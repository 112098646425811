<template>
	<div>
		<a-modal title="选择应用" :width="700" :visible="visible"  @cancel="handleCancel" :footer="null">
			<div>
				<div class="wxb-table-gray">
					<a-table rowKey="app_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas" :loading="loading">
						<div slot="logo" slot-scope="logo,record">
							<img v-if="logo!=null" :src="logo" style="width: 40px;height: 40px;"/>
							<img v-else src="../../../../../assets/image/woyaoyou_application01@2x.png" style="width: 40px;height: 40px;"/>
						</div>
						
						<template slot="action" slot-scope="text,record">
							<a-button type="primary" @click="chooseIt(record)">选择</a-button>
						</template>
					</a-table>
				</div>
			</div>
		</a-modal>
		
		<a-modal title="生成激活码" :width="400" :visible="addvisible"  @cancel="handleAddCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleAddCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定生成
				</a-button>
			</template>
			<div>
				
				<a-form-model
				   ref="ruleForm"
					:model="form"
					:label-col="{ span: 6 }" 
					:wrapper-col="{ span: 18}">	
					<a-form-model-item label="有效天数" required>
						 <a-input-number style="width: 200px;" :formatter="value => `${value}天`" v-model="form.days" :precision="0" :min="0" />
					</a-form-model-item>
					
					<a-form-model-item label="作用天数" required help="用于让商铺延长多少天">
						 <a-input-number style="width: 200px;" :formatter="value => `${value}天`" v-model="form.valid_days" :precision="0" :min="0" />
					</a-form-model-item>
					
					<a-form-model-item label="价格" required help="激活码的价格">
						 <a-input-number style="width: 200px;" :formatter="value => `¥${value}`" v-model="form.price" :precision="2" :min="0" />
					</a-form-model-item>
					
					<a-form-model-item label="生成几个" required>
						 <a-input-number style="width: 200px;" :formatter="value => `${value}个`" v-model="form.unit" :precision="0" :min="0" :max="50" />
					</a-form-model-item>
				</a-form-model>
				
			</div>
		</a-modal>
	</div>
</template>

<script>
	import {listMixin} from '../../../../../common/mixin/list.js';
	export default {
		mixins:[listMixin],
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
		},
		data() {
			return {
				loading: false,
				confirmLoading:false,
				addvisible:false,
				app_id:0,
				columns: [
					{title: '排序',dataIndex: 'sort',align: 'center',ellipsis: true},
					{title: '应用名称',dataIndex: 'name',align: 'center',ellipsis: true},
					{title: 'logo',dataIndex: 'logo',align: 'center',scopedSlots: {customRender: 'logo'}},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
					
				],
				datas: [],
				form:{
					days:'',
					unit:'',
					valid_days:'',
					price:'',
				}
			}
		},
		created() {
			
		},
		methods: {
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/admin/getApplianceList',{
					status:1,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
				}).catch(res=>{
					console.log(res);
				}).finally(()=>{
					this.loading=false;
				})
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
			chooseIt(record){
				this.app_id = record.app_id;
				this.addvisible=true;
			},
			
			handleAddCancel(){
				this.addvisible=false;
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
		
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('platform/admin/generateActivationCode',{
					app_id:this.app_id,
					days:this.form.days,
					unit:this.form.unit,
					valid_days:this.form.valid_days,
					price:this.form.price
				}).then(res=>{
					this.$message.success('操作成功',1,()=>{
						this.confirmLoading=false;
						this.addvisible=false;
						this.$emit("ok");
					})
				}).catch(res=>{
					this.confirmLoading=false;
				})
				
			},
			
		}
	}
</script>

<style>
</style>
